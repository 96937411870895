.cards-container {
  @extend %flex;
  align-items: center;
  justify-content: space-around;
  margin: 2% 0 1%;
  text-align: center;

  &__card {
    margin: 4% 0;
    @include md {
      margin: 0;
    }
  }

  & img {
    max-width: 60px;
  }
}

.brand-bar {
  @extend %flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 2%;
  padding-bottom: 2%;

  img {
    max-width: 60px;
  }

  &__small img {
    max-width: 140px;
  }
}

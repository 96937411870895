form {
    @extend %flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0 auto;
    max-width: 350px;

    label {
        @extend %regular-font;
        align-self: flex-start;
    }
    input,textarea {
        width: 100%;
    }
    button {
        align-self: flex-start;
        cursor: pointer;
    }
    
}
* {
  box-sizing: border-box;
  margin: 0;
}

body, button {
  color: $charcoal;
  font-family: muli, sans-serif;
  font-style: normal;
  font-weight: 400;
}

img {
  height: auto;
  width: 100%;
}

.gallery {
  @extend %grid;
  margin: 0 0 1%;

  &__card {
    @extend %col-1-3;
    position: relative;
  }

  &__card img {
    height: 100%;
  }

  &__card:hover &__card-overlay {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.5s ease;
  }

  &__card:hover &__card-overlay p {
    background: #fff;
    border-radius: 3px;
    color: $charcoal;
    display: inline-block;
    font-weight: 600;
    left: 50%;
    padding: 1% 5%;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  & p {
    display: none;
  }
}

.deskOnly {
  display: none;
  @include md {
    display: inline-block;
  }
}
